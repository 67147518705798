/* bouton déconnexion */

.logout__button{

    font-size: 16px;
    line-height: 110%;

    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(330.7deg, #E73356 15.71%, #EE7259 46.1%, #F2915B 79.03%);
    border-radius: 5px;
    border-color: #ff004f;
    flex: none;
    flex-grow: 0;
}

/* Logo SF Helper */

.logo{
    width: 40%;
    margin-left: 180px;

}

/* bouton Générez une thématique */

.Button__thematic{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;


    background: #242A30;
    border-radius: 5px;

    /* Inside auto layout */

    position: static;
    width: 140px;
    height: 45px;

    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    text-align: center;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

/* bouton Générez le code */

.generate__button{

    font-size: 16px;
    line-height: 110%;

    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(330.7deg, #E73356 15.71%, #EE7259 46.1%, #F2915B 79.03%);
    border-radius: 5px;
    flex: none;
    flex-grow: 0;
}

/* bouton inserez le code */

.insert__button{
    position: static;
    font-size: 16px;
    line-height: 110%;

    text-align: center;

    flex: none;
    order: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(129.95deg, #0655f3 -14.63%, #44BDEE 110.3%);
    border-radius: 5px;


    flex: none;
    flex-grow: 0;
}

.thematic__characteristics{

    font-size: 16px;
    line-height: 110%;

    flex: none;
    order: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #000000;
    background-color: #F2F5FB;
    border-radius: 5px;
    border-color: #E73356 #EE7259;
    flex: none;
    flex-grow: 0;
}

.add__thematic{
    position: static;
    font-size: 16px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(330.7deg, #E73356 15.71%, #EE7259 46.1%, #F2915B 79.03%);
    border-radius: 5px;

    flex: none;
    flex-grow: 0;

    margin-left: 40px;
    margin-top: 40px;
}

.admin__button{

    font-size: 16px;
    line-height: 110%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(129.95deg, #0655f3 -14.63%, #44BDEE 110.3%);
    border-radius: 5px;
    flex: none;
    flex-grow: 0;
}

.input__thematic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;

    position: static;
    width: 300px;
    height: 50px;



    background: #FFFFFF;
    border-radius: 5px;

}

.add__creation__thematic{



    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(129.95deg, #0655f3 -14.63%, #44BDEE 110.3%);
    border-radius: 5px;

    flex: none;
    flex-grow: 0;

}

.pop__cancel{



    display: flex;
    flex-direction: row;
    align-items: flex-start;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(129.95deg, #0655f3 -14.63%, #44BDEE 110.3%);
    border-radius: 5px;

    flex: none;
    flex-grow: 0;

}

.pop__delete{



    display: flex;
    flex-direction: row;
    align-items: flex-start;

    /* White */

    color: #FFFFFF;
    background: linear-gradient(129.95deg, rgba(149,36,36,1) 0%, rgba(255,0,0,1) 100%);
    border-radius: 5px;

    flex: none;
    flex-grow: 0;

}
